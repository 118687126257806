import React from "react"

import Layout from "../components/common/Layout/layout"
import SEO from "../components/common/SEO/seo"
import BannerPage from "../components/banner/BannerPage/bannerPage"
import CallToAction from "../components/section/CallToAction/callToAction"

const PoliticaPrivacidadPage = () => {

    return (
        <Layout>
            <SEO
                title="Política de privacidad"
                description="Accede a tos los contenidos de tu agencia de marketing digital especializada en posicionamiento web, SEM, diseño web, diseño gráfico, producción gráfica y producción audiovisual."
            />

            <BannerPage
                title="Política de privacidad"
                text="Siempre cumpliendo con la legislación actual"
                color="green"
            />

            <section className="container space-2-bottom space-3-bottom--lg">
                <div className="row">
                    <div className="col-12">
                        <p>
                            <strong>1. USO Y TRATAMIENTO DE DATOS DE CARÁCTER PERSONAL.</strong><br /><br />
                            En cumplimiento de lo establecido en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (en adelante LOPD), IBX DIGITAL SOLUTIONS S.L., en adelante LA EMPRESA le informa que los datos de carácter personal proporcionados, a través del Website, así como los que pudiera facilitar en el futuro en el marco de otros canales, serán incorporados en los ficheros automatizados titularidad de LA EMPRESA, cuyos datos constan en el encabezamiento.<br /><br />
                            Estos ficheros tienen la finalidad de gestionar, administrar, prestarle los servicios o facilitarle los productos que solicite, facilitar el cumplimiento y ejecución de los contratos que pudiera celebrar, conocer mejor sus gustos y adecuar los servicios a sus preferencias, así como poder ofrecerle nuevos servicios o productos y enviarle información administrativa, técnica, organizativa y/o comercial de forma documental y/o electrónica relacionada con las actividades de LA EMPRESA. Los destinatarios de la información recogida serán exclusivamente los propios empleados de LA EMPRESA.<br /><br />
                            Salvo en los campos en que expresamente se determine lo contrario por medio de un asterisco (*), las respuestas a las preguntas sobre datos personales son voluntarias, sin que la falta de contestación a dichas preguntas implique una merma en la calidad de los servicios que Usted solicita. La falta de cumplimentación de los campos determinados como obligatorios o el suministro de datos incorrectos imposibilitará que LA EMPRESA pueda prestarle los servicios o le facilite los productos que solicite.<br /><br />
                            <strong>2. EJERCICIO DE DERECHOS: ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN</strong><br /><br />
                            Aquellas personas físicas que hayan facilitado sus datos a LA EMPRESA podrán dirigirse a la entidad, en su calidad de Responsable del fichero, con el fin de poder ejercitar gratuitamente sus derechos de acceso, rectificación, cancelación y oposición respecto de los datos incorporados en sus ficheros.<br /><br />
                            Dado el carácter confidencial de la información, Vd. no podrá ejercitar sus derechos telefónicamente, se debe solicitar por cualquier medio que deje constancia de su envío y de su recepción y remitir copia de su DNI o documento equivalente.<br /><br />
                            El interesado podrá ejercitar sus derechos mediante comunicación por escrito dirigida a LA EMPRESA en la dirección indicada al inicio o a la dirección de correo electrónico info@ibxagency.com.<br /><br />
                            <strong>3. MEDIDAS DE SEGURIDAD</strong><br /><br />
                            LA EMPRESA le informa que tiene implantadas las medidas de seguridad de índole técnica y organizativas necesarias para garantizar la seguridad de sus datos de carácter personal y evitar su alteración, pérdida y tratamiento y/o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos, ya provengan de la acción humana o del medio físico o natural. Todo ello, de conformidad con lo previsto en el artículo 9 de la LOPD y en el Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de diciembre, de protección de datos de carácter personal.<br /><br />
                            Así, LA EMPRESA ha establecido medidas adicionales en orden a reforzar la confidencialidad e integridad de la información en la organización. No obstante, el usuario debe ser consciente de que las medidas de seguridad en Internet no son inexpugnables.<br /><br />
                            LA EMPRESA continuamente mantiene la supervisión, control y evaluación de los procesos para asegurar el respeto a la privacidad de los datos.<br /><br />
                            <strong>4. USO DE COOKIES</strong><br /><br />
                            Las "cookies" constituyen una herramienta empleada por los servidores Web para almacenar y recuperar información acerca de sus usuarios.<br /><br />
                            Las "cookies" son mensajes enviados por el servidor al ordenador del usuario, consistentes en ficheros de texto que se almacenan en la memoria del ordenador y que recogen información relativa a las páginas que se visitan, el tiempo de conexión a Internet, etc. Las "cookies" vuelven a ser enviadas al servidor cada vez que el usuario entra en esa página.<br /><br />
                            La finalidad de las "cookies" de LA EMPRESA es personalizar los servicios que le ofrecemos, facilitándole información que pueda ser de su interés. Si no desea que se instale en su disco duro una cookie puede configurar el navegador de su ordenador para no recibirlas. Sin embargo, le hacemos notar que, en ese caso, la calidad del funcionamiento del Website puede disminuir.<br /><br />
                            Las "cookies" se asocian únicamente con un usuario anónimo y su ordenador, y no proporcionan por sí solas los datos personales del cliente, ni pueden leer datos de su disco duro, ni leer los archivos cookie creados por otros proveedores.<br /><br />
                            Los "logs" son unos archivos almacenados en el servidor de LA EMPRESA que registran datos sobre su navegación y nos permiten seguirle prestando los servicios que solicita. Asimismo, nos ayudan a analizar el funcionamiento del sistema, localizar las incidencias y problemas que puedan surgir y solventarlos en el menor plazo posible.<br /><br />
                            <strong>5. MENORES DE EDAD</strong><br /><br />
                            LA EMPRESA considera que la protección de datos de carácter personal es esencial, por lo que en el supuesto de que el usuario sea menor de edad, éste sólo podrá aportar sus datos personales en los formularios de recogida de los mismos, con el previo consentimiento de los padres o tutores, enviando el formulario correspondiente debidamente firmado por los padres o tutores por correo certificado y con copia del DNI del padre o tutor firmante a la dirección de LA EMPRESA arriba indicada.<br /><br />
                            LA EMPRESA no responde de aquellos datos de menores que sin poder conocer LA EMPRESA este hecho se haya facilitado sin el consentimiento de los padres o tutores.<br /><br />
                            <strong>6. MODIFICACIÓN DE LA POLÍTICA DE PRIVACIDAD</strong><br /><br />
                            LA EMPRESA se reserva el derecho a modificar su Política de Privacidad, de acuerdo a su propio criterio, o motivado por un cambio doctrinal de la Agencia Española de Protección de Datos, legislativo o jurisprudencial. Cualquier modificación de la Política de Privacidad será publicada al menos diez días antes de su efectiva aplicación. El uso del Web después de dichos cambios, implicará la aceptación de éstos.
                        </p>
                    </div>
                </div>
            </section>


            <CallToAction
                color="green"
                title="¿Necesitas un plan de marketing digital para tu empresa?"
                text="Somos tu Agencia de Marketing Digital"
                button="Pide presupuesto ¡Gratis!"
                buttonUrl="/contacto"
            />



        </Layout>
    )

}
export default PoliticaPrivacidadPage
